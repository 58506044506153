<template>
	<div class="text-block">
		<h2 v-if="!isLoad" class="text-block__title">{{ title }}</h2>
    <or-skeleton v-else class="text-block__title--skeleton"/>
		<div v-if="!isLoad" class="text-block__content">
			<Description :content="text" />
		</div>
    <or-skeleton v-else class="text-block__content--skeleton"/>
		<or-button class="text-block__submit" @click="buy">
			{{ $t('general.buyApartment') }}
		</or-button>
	</div>
</template>

<script>
import Description from '@/components/SoloPages/Description.vue'

export default {
	components: { Description },
	props: {
    isLoad: {
      type: Boolean,
      default: false
    },
		title: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		}
	},
	methods: {
		buy() {
			this.$store.commit('showModal', {
				name: 'CreateRequestBuy'
			})
		}
	}
}
</script>

<style lang="scss">
.text-block {
	display: flex;
	justify-content: space-between;
	flex-direction: column;

  &__title {
    margin: 0;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &--skeleton {
      width: 100%;
      height: 63px;
      margin-bottom: 30px;
    }
  }

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		grid-gap: 20px;
		padding-bottom: 10px;
		margin-bottom: 10px;

    &--skeleton {
      width: 100%;
      height: 100px;
      margin-bottom: 10px;
    }
	}

	&__submit {
    max-width: 180px;
    margin: 0 auto;
    padding: 12px 20px;
	}
}
</style>
