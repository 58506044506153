<template>
	<div class="solo-area">
		<div class="container">
			<Breadcrumb :pages="pages" class="solo-area__breadcrumb" />
			<Images
				v-if="!isLoad"
				:images="infoArea.images?.map((el) => el)"
				class="solo-area__images"
			/>
			<or-skeleton v-else class="solo-area__images--skeleton" />

			<Text
				:is-load="isLoad"
				:text="infoArea.description"
				:title="infoArea.full_name || infoArea.title"
				class="solo-area__block"
			/>

			<template v-if="!isLoad">
				<Gallery
					class="solo-area__gallery"
					v-if="infoProject.items?.length"
					:title="$t('general.projects')"
					:content="infoProject.items"
					:options="{ alt: 'Project logo' }"
					card="ProjectCard"
					type-card="small"
					@cardClick="redirect"
				/>
			</template>
			<or-skeleton v-else style="width: 100%; height: 330px" />
		</div>
	</div>
</template>

<script>
import { setCommonMetaData } from '@/utils/setMetaData'
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { i18n } from '@/i18n'

import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

import Breadcrumb from '@/components/Breadcrumb'
import Gallery from '@/components/Gallery.vue'
import Images from '@/components/SoloPages/Images'
import Text from '@/components/SoloPages/Text'

export default {
	name: 'SoloArea',
	components: { Breadcrumb, Images, Text, Gallery },
	setup() {
		const infoArea = ref({})
		const infoProject = ref({})
		const isLoad = ref(true)

		const router = useRouter()
		const route = useRoute()
		const store = useStore()

		const pages = computed(() => ({
			first: {
				title: i18n.global.t('general.areas'),
				srcName: 'Areas'
			},
			current: infoArea.value?.title
		}))

		const redirect = (_, payload) => {
			router.push({
				name: 'SoloProject',
				params: { slug: payload.slug, lang: store.state.uiLanguage }
			})
		}

		const get = () => {
			createRequest(requestConfigs.GETAreaInfo, {
				routerPayload: { slug: route.params.slug }
			})
				.then((result) => {
					infoArea.value = result.response

					setCommonMetaData(
						`${i18n.global.t('titlePages.area', {
							name: infoArea.value.title
						})} - OWRealty`,
						{
							description: i18n.global.t('meta.areaDesc'),
							type: 'article',
							title: `${i18n.global.t('titlePages.area', {
								name: infoArea.value.title
							})} - OWRealty`,
							url: window.location.href
						}
					)
				})
				.catch((err) =>
					store.commit('setError', { status: err.errCode || 404 })
				)
				.finally(() => (isLoad.value = false))

			createRequest(requestConfigs.GETProjectList, {
				queryPayload: { region_slug: route.params.slug }
			}).then((result) => (infoProject.value = result.response))
		}

		get()

		return {
			isLoad,
			infoArea,
			infoProject,

			pages,

			carouselSettings: {
				settings: {
					itemsToShow: 4,
					snapAlign: 'start',
					itemsToScroll: 4,
					wrapAround: false
				},
				breakpoints: {
					320: { itemsToShow: 1 },
					460: { itemsToShow: 2 },
					920: { itemsToShow: 3, touchDrag: true },
					1100: { itemsToShow: 4, itemsToScroll: 0, touchDrag: false }
				}
			},

			redirect
		}
	}
}
</script>

<style lang="scss">
.solo-area {
	padding-bottom: 50px;

	&__breadcrumb {
		margin-bottom: 30px;
	}

	&__images {
		margin-bottom: 40px;

		&--skeleton {
			height: 380px;
			width: 100%;
			margin-bottom: 40px;

			@media screen and (max-width: 768px) {
				height: 300px;
			}
		}
	}

	&__block {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;
		}
	}
	&__gallery {
		.project-card__image {
			width: 280px;
			border-radius: 5px;
			overflow: hidden;
		}
		.skeleton-basic {
			aspect-ratio: 16 / 10;
		}
	}

	@media screen and (max-width: 768px) {
		padding-bottom: 40px;
	}
}
</style>
